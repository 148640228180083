import React from 'react'
import styled from '@emotion/styled'

const PartnerEmbedVideo = props => {
  const currentPartner = props.teamPartners
  // console.log(props, 'props')

  // const [currentPartner, setCurrentPartner] = useState(teamPartners[0])

  // console.log(currentPartner, 'currentPartner')

  const VideoEmbedContainer = styled.div``

  return (
    <VideoEmbedContainer>
      {/* videoEmbed {
                 videoEmbed
                 childMarkdownRemark {
                   html */}
      {/* <div
        // dangerouslySetInnerHTML={{
        //   __html: currentPartner.videoEmbed.childMarkdownRemark.html,
        // }}
      >
        <iframe
          src={currentPartner.videoEmbed.videoEmbed}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> */}
    </VideoEmbedContainer>
  )
}

export default PartnerEmbedVideo
