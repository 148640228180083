import React from 'react'
import arrow from '../assets/images/arrow6.png'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

function Arrow({ children, disabled, onClick, handleMove }) {
  return (
    <button
      onMouseLeave={() => {
        // console.log('mouse leave')
      }}
      onMouseEnter={() => {
        // console.log('mouse enter')
        if (handleMove) {
          handleMove()
        }
      }}
      disabled={disabled}
      className="arrowContainer"
      onClick={onClick}
      style={{
        opacity: disabled ? '0' : '1',
        userSelect: 'none',
      }}
    >
      {children}
    </button>
  )
}

export function createLeftArrow(moveLeft) {
  const LeftArrow = function() {
    const {
      isFirstItemVisible,
      scrollPrev,
      visibleElements,
      initComplete,
    } = React.useContext(VisibilityContext)

    const [disabled, setDisabled] = React.useState(
      !initComplete || (initComplete && isFirstItemVisible)
    )
    React.useEffect(() => {
      // NOTE: detect if whole component visible
      if (visibleElements.length) {
        setDisabled(isFirstItemVisible)
      }
    }, [isFirstItemVisible, visibleElements])

    return (
      <Arrow
        disabled={disabled}
        onClick={() => scrollPrev()}
        handleMove={moveLeft}
      >
        <img src={arrow} alt="arrow-left" />
      </Arrow>
    )
  }
  return LeftArrow
}

export function createRightArrow(moveRight) {
  const RightArrow = function() {
    const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(
      VisibilityContext
    )

    const [disabled, setDisabled] = React.useState(
      !visibleElements.length && isLastItemVisible
    )
    React.useEffect(() => {
      if (visibleElements.length) {
        setDisabled(isLastItemVisible)
      }
    }, [isLastItemVisible, visibleElements])

    return (
      <Arrow
        disabled={disabled}
        onClick={() => scrollNext()}
        handleMove={moveRight}
      >
        <img src={arrow} alt="arrow-right" />
      </Arrow>
    )
  }
  return RightArrow
}
