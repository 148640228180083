import React, { useState } from 'react'
import CarouselLogos from './CarouselLogos'
import PartnerEmbedVideo from './PartnerEmbedVideo'
import CarouselMini from './CarouselMini'
import VideoEmbed from './VideoEmbed'
import styled from '@emotion/styled'

const PartnerNetwork = data => {
  const teamPartners = data.teamPartners

  const [currentPartner, setCurrentPartner] = useState(teamPartners[0])

  const changeCurrentPartner = partner => {
    setCurrentPartner(partner)
  }

  const PartnerContainerInfo = styled.div`
    height: 100%;
    display: flex;
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 820px) {
      display: flex;
      flex-direction: column;
      align-items: center;

    }
  `

  const PartnerAboutContent = styled.div`
    width: calc(50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 30px;
    transform: translateY(50px);

    .partnerHeadliner {
      font-weight: 800;
      white-space: nowrap;
      padding-bottom: 20px;
      color: white;
      line-height: 1.3;
      font-size: 1.6rem;
      text-align: center;
      text-decoration: none;
    }

    .partnerHeadliner:hover {
      text-decoration: underline;
    }

    .partnerDescription {
      /* width: calc(65%); */
      font-weight: 300;

      p {
        text-align: center;
        line-height: 1.6;
      }
    }

    .partnerWebsite {
      padding-top: 20px;

      a {
        text-decoration: underline;
      }

      a:hover {
        text-decoration: none;
        color: blue;
      }
    }

    @media (max-width: 820px) {
      width: 100%;

      .partnerDescription {
        p {
          margin-bottom: 20px;
          text-align: center;
        }
      }
    }
  `
  return currentPartner ? (
    <div>
      <CarouselLogos
        teamPartners={teamPartners}
        onPartnerClick={changeCurrentPartner}
      />
      <PartnerContainerInfo className="partnerContainerInfo">
        <PartnerAboutContent className="partnerAbout">
          <a
            className="partnerHeadliner"
            href={currentPartner.partnerWebsite}
            target="_blank"
            rel="noopener noreferrer"
          >
            {currentPartner.partnerName}
          </a>
          <p
            className="partnerDescription"
            dangerouslySetInnerHTML={{
              __html:
                currentPartner.partnerDescription.childMarkdownRemark.html,
            }}
          ></p>
          <p className="partnerWebsite">
            Website: &nbsp;
            <a
              href={currentPartner.partnerWebsite}
              target="_blank"
              rel="noopener noreferrer"
            >
              {currentPartner.partnerName}
            </a>
          </p>
        </PartnerAboutContent>
        {currentPartner.carouselSliderMedia ? (
          <CarouselMini
            images={currentPartner.carouselSliderMedia}
          ></CarouselMini>
        ) : null}
      </PartnerContainerInfo>
      <PartnerEmbedVideo />
      <VideoEmbed videoEmbed={currentPartner.videoEmbed}></VideoEmbed>
    </div>
  ) : null
}

export default PartnerNetwork
