import React from 'react'
import styled from '@emotion/styled'

const VideoEmbed = props => {

  const VideoEmbedContainer = styled.div`
    margin-bottom: 10%;
  `

  return (
    <VideoEmbedContainer>
      {props.videoEmbed ? (
        <div
          dangerouslySetInnerHTML={{
            __html: props.videoEmbed.childMarkdownRemark.html,
          }}
        ></div>
      ) : null}
    </VideoEmbedContainer>
  )
}

export default VideoEmbed
