import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/Container'
import SEO from '../components/SEO'
import TextEffect from '../components/riseEffect/TextEffect'
import styled from '@emotion/styled'
import PartnerNetwork from "../components/PartnerNetwork.js"

const PageTemplate = ({ data }) => {
  const { title, abstract, metaDescription, slug, body } = data.contentfulPage;

  const teamPartners = data.allContentfulPartnerNetwork.nodes;

  const TitleContainer = styled.section`
    margin: 40px 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  `
  const TextContainer = styled.section`
    padding: 40px 0 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .abstract {
      width: 30%;
      font-size: 1.6rem;
      text-align: center;
      margin: 15px 0;

      p {
        line-height: 1.3;
      }
    }

    .paragraph {
      width: calc(65%);
      font-weight: 300;
      text-align: center;

      p {
        line-height: 1.6;
      }
    }

    .date {
      p {
        font-size: 0.8rem;
        font-weight: 700;
      }
    }

    @media (max-width: 700px) {
      .abstract {
        width: 50%;
      }
    }

    @media (max-width: 480px) {
      width: 100%;

      .abstract {
        width: 40%;
        margin: 10px 0;
      }

      .paragraph {
        width: calc(90%);
      }
    }
  `

  return (
    <div name={`partnernetwork`}>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        slug={slug}
      />
      <Container>
        <div
          style={{
            margin: '0 auto auto',
            maxWidth: '1300px',
          }}
        >
          <TitleContainer>
            <h1>{title}</h1>
            {/* <ScrollingText text={title} /> */}
          </TitleContainer>

          {abstract && body ? (
            <TextContainer>
              <div className="abstract">
                <TextEffect text={abstract.abstract} />
              </div>

              <div className="paragraph">
                <TextEffect text={body.body} />
              </div>
            </TextContainer>
          ) : null}

          {slug === 'partnernetwork' ? (
            <PartnerNetwork teamPartners={teamPartners} />
          ) : null}
        </div>
      </Container>
    </div>
  )
}

export const query = graphql`
         query($slug: String!) {
           contentfulPage(slug: { eq: $slug }) {
             title
             slug
             metaDescription {
               internal {
                 content
               }
             }
             body {
               childMarkdownRemark {
                 html
               }
               body
             }
             abstract {
               childMarkdownRemark {
                 html
               }
               abstract
             }
           }
           allContentfulPartnerNetwork(
             sort: { fields: partnerOrder, order: ASC }
           ) {
             nodes {
               id
               partnerName
               carouselLogo {
                 id
                 fluid(maxWidth: 800) {
                   ...GatsbyContentfulFluid_withWebp
                 }
                 file {
                   url
                 }
                 description
               }
               carouselHoverMedia {
                 id
                 fluid(maxWidth: 800) {
                   ...GatsbyContentfulFluid_withWebp
                 }
                 file {
                   url
                 }
                 description
               }
               partnerDescription {
                 partnerDescription
                 childMarkdownRemark {
                   html
                 }
               }
               carouselSliderMedia {
                 id
                 fluid(maxWidth: 800) {
                   ...GatsbyContentfulFluid_withWebp
                 }
                 file {
                   url
                 }
                 description
               }
               metaDescription {
                 metaDescription
                 childMarkdownRemark {
                   html
                 }
               }
               videoEmbed {
                 videoEmbed
                 childMarkdownRemark {
                   html
                 }
               }
               partnerWebsite
             }
           }
         }
       `

export default PageTemplate
