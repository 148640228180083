import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
// import gsap from 'gsap'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import useDrag from './useDrag.js'
import './CarouselMini.css'
import { createLeftArrow, createRightArrow } from './ArrowCarousel.js'

const CarouselMini = props => {
  const { dragStart, dragStop, dragMove } = useDrag()

  // const handleItemClick = partner => () => {
  //   if (dragging) {
  //     return false
  //   }
  //   // setCurrentPartner(partner)
  //   // event.preventDefault()
  //   props.onPartnerClick(partner)

  //   // setSelected(selected !== itemId ? itemId : '')
  //   // console.log(
  // }

  const handleDrag = ({ scrollContainer }) => ev =>
    dragMove(ev, posDiff => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff
      }

      if (scrollContainer.current.scrollLeft === 0) {
        scrollContainer.current.scrollLeft = 0
      }
    })

  const apiRef = React.useRef({})

  const moveLeft = () => {
    if (apiRef.current) {
      apiRef.current.scrollPrev()
    }
  }

  const moveRight = () => {
    if (apiRef.current) {
      apiRef.current.scrollNext()
    }
  }

  // const [count, setCount] = useState(0)
  const [mousedOver, setMousedOver] = useState(false)

  useEffect(() => {
    // set an interval timer if we are currently moused over
      // a cada um segundo irá
      const timer = setInterval(() => {
        // cycle prevCount using mod instead of checking for hard-coded length
        // setCount(prevCount => (prevCount + 1))

        // if (count === props.images.length - 1) {
        // console.log('apiRef', apiRef.current)
        // apiRef.scrollToItem(apiRef.getItemElementById)
        // }

        // console.log('isLastItem', apiRef.current.isLastItem())
        // console.log('isFisrtItemVisible', apiRef.current.isFisrtItemVisible())
        // console.log('isLastItem', apiRef.current.isLastItem())
        // console.log('isLastItem', apiRef.current.isLastItem())
        // console.log('isLastItem', apiRef.current.isLastItem())

        // if is the last item, scroll to the first item
        if (apiRef.current.isLastItemVisible) {
          console.log('isLastItem true')
          apiRef.current.scrollContainer.current.scrollLeft = 0

        } else {
          moveRight()
        }

        //

        // console.log('count', count)
      }, 4000)
      // automatically clear timer the next time this effect is fired or
      // the component is unmounted
      return () => clearInterval(timer)
    // if (mousedOver) {

    // } else {
    //   // otherwise (not moused over), reset the counter
    //   // setCount(0)
    // }
    // // the dependency on mousedOver means that this effect is fired
    // // every time mousedOver changes
  }, [])

  return (
    <section className="carouselMiniContainer" onMouseLeave={dragStop}>
      <ScrollMenu
        apiRef={apiRef}
        LeftArrow={createLeftArrow(moveLeft)}
        RightArrow={createRightArrow(moveRight)}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {props.images.map(image => {
          return (
            <li
              key={image.id}
              // className={currentPartner === image ? `active` : ''}
              // onClick={handleItemClick(image)}
              onMouseOver={() => setMousedOver(true)}
            >
              <Img
                fluid={image.fluid}
                className={`carouselSliderMedia`}
                draggable={false}
              />
            </li>
          )
        })}
      </ScrollMenu>
    </section>
  )
}

export default CarouselMini
