import React, { useState } from 'react'
import Img from 'gatsby-image'
import gsap from 'gsap'
import Video from './Video.js'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import useDrag from './useDrag.js'
import './CarouselLogos.css'
import { createLeftArrow, createRightArrow } from './ArrowCarousel.js'

const CarouselLogos = props => {
  const [currentPartner, setCurrentPartner] = useState(props.teamPartners[0])

  const { dragStart, dragStop, dragMove, dragging } = useDrag()

  const handleItemClick = partner => () => {
    if (dragging) {
      return false
    }
    setCurrentPartner(partner)
    // event.preventDefault()
    props.onPartnerClick(partner)

    // setSelected(selected !== itemId ? itemId : '')
    // console.log(
  }

  const handleDrag = ({ scrollContainer }) => ev =>
    dragMove(ev, posDiff => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff
      }

      if (scrollContainer.current.scrollLeft === 0) {
        scrollContainer.current.scrollLeft = 0
      }
    })

    const apiRef = React.useRef({});

    const moveLeft = () => {
      if (apiRef.current) {
        const apiCurrent = apiRef.current
        apiCurrent.scrollToItem(apiCurrent.getPrevItem())
      }
    }

    const moveRight = () => {
      if (apiRef.current) {
        // apiRef.current.scrollNext();
        const apiCurrent = apiRef.current;

        // this line move the scroll to right side 300px
        // c.scrollContainer.current.scrollLeft+= 300;

        apiCurrent.scrollToItem(apiCurrent.getNextItem())

      }
    }


  console.log('qualquer coisa')

  return (
    <section>
      {/* OverallContainer */}

      {/*  Carousel Container */}
      <div onMouseLeave={dragStop}>
        <div className="carouselContainer">
          <ScrollMenu
            apiRef={apiRef}
            LeftArrow={createLeftArrow(moveLeft)}
            RightArrow={createRightArrow(moveRight)}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
          >
            {props.teamPartners.map(partner => {
              const hoverMedia = partner.carouselHoverMedia
              return (
                <li
                  key={partner.id}
                  className={currentPartner === partner ? `active` : ''}
                  onClick={handleItemClick(partner)}
                >
                  <Img
                    fluid={partner.carouselLogo.fluid}
                    className={`carouselImageLogo`}
                    draggable={false}
                  />
                  {hoverMedia ? (
                    <Video
                      videoSrcURL={hoverMedia.file.url}
                      className={`teamPartnerHoverMediaVideo`}
                      description={hoverMedia.description}
                    />
                  ) : null}
                </li>
              )
            })}
          </ScrollMenu>
        </div>
      </div>
    </section>
  )
}

export default CarouselLogos
